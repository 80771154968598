<template>
  <ToolsFormDrilling
    :prop-bearing-width="bearingWidth"
    :prop-bearing-length="bearingLength"
    :prop-holes-diameter="holesDiameter"
    :prop-holes-count="holesCount"
    :prop-holes-y1="holesY1"
    :prop-holes-y2="holesY2"
    :prop-holes-x1="holesX1"
    :prop-holes-x2="holesX2"
    :prop-holes-x3="holesX3"
    :disable-inputs="disableInputs"
  />
</template>

<script>
import ToolsFormDrilling from '../../components/tools/ToolsFormDrilling.vue';
import OutputHelper from '../../helpers/OutputHelper';

export default {
  components: {
    ToolsFormDrilling,
  },
  data() {
    return {
      bearingWidth: this.$store.state.fixedBearingWidth,
      bearingLength: this.$store.state.fixedBearingLength,
      holesDiameter: this.$store.state.fixedBearingHolesDiameter,
      holesCount: this.$store.state.fixedBearingHolesCount,
      holesY1: OutputHelper.convertToInteger(
        this.$store.state.fixedBearingL1
        - (this.$store.state.fixedBearingHolesDiameter / 2),
      ),
      holesY2: OutputHelper.convertToInteger(
        this.$store.state.fixedBearingL2
        - (this.$store.state.fixedBearingHolesDiameter / 2),
      ),
      holesX1: OutputHelper.convertToInteger(
        this.$store.state.fixedBearingM1
        - (this.$store.state.fixedBearingHolesDiameter / 2),
      ),
      holesX2: this.$store.state.fixedBearingM0 - this.$store.state.fixedBearingHolesDiameter,
      holesX3: OutputHelper.convertToInteger(
        this.$store.state.fixedBearingM2
        - (this.$store.state.fixedBearingHolesDiameter / 2),
      ),
      disableInputs: [
        'bearingWidth',
        'bearingLength',
        'bearingThickness',
        'holesCount',
        'holesDiameter',
        'holesY1',
        'holesY2',
        'holesX1',
        'holesX2',
        'holesX3',
      ],
    };
  },
};
</script>

<style scoped lang="scss">

</style>
